<template>
    <div class="content-wrapper">
        <div class="content">
            <OnBoarding CRMSystemSetting></OnBoarding>
            <component
                @createConnector="createConnector"
                @cancel="cancel"
                v-if="activeComponent"
                :is="activeComponent"
            ></component>
            <h2 class="dashboard mt-12" v-else>
                В настоящее время настройки в этом источнике не поддерживается
            </h2>
        </div>
    </div>
</template>

<script>
import OnBoarding from '@/components/project/onBoarding/OnBoarding.vue'
import cfg from '@root/config'
import { computed, ref, watchEffect } from 'vue'
import { mapState } from 'vuex'
import settingsList from '@/components/connectorSettings/list'
import { CONNECTOR_TYPES } from '@/vars/general'

export default {
    name: 'SettingsCRM',
    components: {
        OnBoarding,
    },
    data: () => ({
        CONNECTOR_TYPES,
        forcedDisabledMarkersStages: [],
    }),
    computed: {
        ...mapState('project', ['currentAddingConnector', 'loggedAddingConnector']),
        project() {
            return this.$store.getters.projectById(this.$router.currentRoute.params.id)
        },
        currentAddingConnectorType() {
            return this.loggedAddingConnector.connectorType
        },
        activeComponent() {
            {
                const comp = settingsList.find(
                    (el) => el.connectorType === this.currentAddingConnectorType
                )?.component
                console.log(comp, 'typew')
                return comp
            }
        },
    },
    methods: {
        async createConnector({ settings, useRedirect }) {
            const projectId = this.$router.currentRoute.params.id
            if (_.isNil(settings)) settings = {}
            if (_.isNil(useRedirect)) useRedirect = true
            settings.isDraft = false
            const connectorId = this.loggedAddingConnector.connectorId
            this.$store.commit('startLoading')
            await this.$store.dispatch('project/updateConnectorSettings', { connectorId, settings })
            this.$store.commit('endLoading')
            this.$store.commit('project/updateCurrentAddingConnector', 'whatsappBasis')
            this.$router.push(`/project/${projectId}/onBoarding/BotSettingsView`)
        },
        cancel() {
            const projectId = this.$router.currentRoute.params.id
            this.$store.dispatch('project/deleteConnector', this.loggedAddingConnector.connectorId)
            this.$router.push(`/project/${projectId}#connectors`)
        },
        next() {
            const projectId = this.$router.currentRoute.params.id
            this.$store.commit('project/updateCurrentAddingConnector', 'whatsappBasis')
            this.$router.push(`/project/${projectId}/onBoarding/BotSettingsView`)
        },
    },
    created() {
        this.$on('goToProject', this.next)
    },
}
</script>
<style lang="sass" scoped>
.content-wrapper
    margin-top: 40px
    .content
        align-items: center
        justify-content: center
        display: flex
        flex-direction: column
        max-width: 81.25%
</style>